import '../scss/index.scss';
import $ from 'jquery';

$(document).ready(function () {
    // StickyNav nav
    var navOffset = $('.nav-wrapper').offset().top;

    var navHeight = $('.nav-wrapper').outerHeight();

    $(window).scroll(function () {
        if ($(window).scrollTop() >= navOffset && $(window).width() > 800) {
            $('.nav-wrapper').addClass('stickyNav');
            $('body').css('padding-top', navHeight);

            if ($(window).width() < 801) {
                $('.nav-wrapper .smith-box').fadeOut(100);
            }
        } else {
            $('.nav-wrapper').removeClass('stickyNav');
            $('body').css('padding-top', 0);

            if ($(window).width() < 801) {
                $('.nav-wrapper .smith-box').fadeIn(100);
            }
        }
    });

    // Nav in page links
    if ($(window).width() < 801) {
        $('.smith-box').unwrap();
    } else {
        $('.smith-box').removeClass('mobile-smith-box');

        $('.nav-links .menu-option, .mobile-smith-box div').on(
            'click',
            function () {
                var linkName = this.childNodes[0].nodeValue
                    .toLowerCase()
                    .replace(/\s/g, '');

                if (linkName !== 'about') {
                    if ($(window).width() < 801) {
                        var scrollOffset = 50;
                    } else {
                        var scrollOffset = 150;
                    }

                    $('html, body').animate(
                        {
                            scrollTop:
                                $('section#' + linkName).offset().top -
                                scrollOffset,
                        },
                        1000
                    );
                }
            }
        );
    }

    // Mobile updates
    if ($(window).width() < 801) {
        // Nav menu
        $('nav .menu-option').on('click', function () {
            $(this).children('.submenu').toggleClass('open');
            $(this).children('i').toggleClass('fa-caret-up');
        });

        // Move promo banner to bottom of billboard
        $('.promobanner').insertAfter('.billboard-slideshow-wrapper');

        // Convert billboard into background image
        var billboardBackground = $('.billboard-slide')
            .eq(0)
            .children('a')
            .children('img')
            .attr('src');
        $('.billboard-slideshow-wrapper').css({
            background:
                'url(' + billboardBackground + ') no-repeat center center',
        });
    }

    // Promo banner close button
    $('.promobanner i.fa-times').on('click', function () {
        $('.promobanner').hide();
    });

    // Billboard slideshow
    if ($(window).width() > 800) {
        $('.billboard-slideshow-wrapper').height(
            $('.billboard-slide').height()
        );
    }

    function debounce(func, delay) {
        let timeoutId;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(function () {
                func.apply(context, args);
            }, delay);
        };
    }

    if ($(window).width() > 800) {
        window.addEventListener(
            'resize',
            debounce(function (event) {
                $('.billboard-slideshow-wrapper').height(
                    $('.billboard-slide').height()
                );
            }, 100)
        );
    }

    if ($('.billboard-slideshow .billboard-slide').length > 1) {
        $('.billboard-slideshow .billboard-slide').each(function () {
            $('.billboard-slideshow-wrapper .slideshow-controls').append(
                '<div class="slideshow-control"></div>'
            );
        });
    }

    var slideTimer = 1;
    var iterations = 0;
    var fader;

    $('.slideshow-control').eq(0).addClass('active');

    $('.slideshow-control').on('click', function () {
        var slideNum = $(this).index();

        $('.slideshow-control').removeClass('active');
        $(this).addClass('active');

        $('.billboard-slideshow .billboard-slide').fadeOut();
        $('.billboard-slideshow .billboard-slide').eq(slideNum).fadeIn();

        slideTimer = $(this).index() + 1;
    });

    if ($('.billboard-slideshow .billboard-slide').length > 1) {
        fader = setInterval(nextSlide, 8000);

        $('.billboard-slideshow-wrapper').hover(
            function () {
                clearInterval(fader);
            },
            function () {
                iterations = 0;
                fader = setInterval(nextSlide, 8000);
            }
        );

        $('.billboard-slideshow-wrapper i.fa').show();
    }

    $('.billboard-slideshow-wrapper i.fa-chevron-right').on(
        'click',
        function () {
            nextSlide();
        }
    );
    $('.billboard-slideshow-wrapper i.fa-chevron-left').on(
        'click',
        function () {
            prevSlide();
        }
    );

    function nextSlide() {
        var totalSlides = $('.billboard-slide').length;

        $('.slideshow-control').removeClass('active');
        $('.billboard-slide').fadeOut();

        if (slideTimer < totalSlides) {
            $('.slideshow-control').eq(slideTimer).addClass('active');
            $('.billboard-slide').eq(slideTimer).fadeIn();
            slideTimer++;
        } else {
            $('.slideshow-control').eq(0).addClass('active');
            $('.billboard-slide').eq(0).fadeIn();
            slideTimer = 1;
            iterations++;
            if (iterations >= 100) {
                clearInterval(fader);
            }
        }
    }

    function prevSlide() {
        var totalSlides = $('.billboard-slide').length;

        $('.slideshow-control').removeClass('active');
        $('.billboard-slide').fadeOut();

        if (slideTimer !== 1) {
            $('.slideshow-control')
                .eq(slideTimer - 2)
                .addClass('active');
            $('.billboard-slide')
                .eq(slideTimer - 2)
                .fadeIn();
            slideTimer--;
        } else {
            $('.slideshow-control')
                .eq(totalSlides - 1)
                .addClass('active');
            $('.billboard-slide')
                .eq(totalSlides - 1)
                .fadeIn();
            slideTimer = totalSlides;
        }
    }

    // Mobile menu submenus
    if ($(window).width() < 801) {
        $('nav .fa-bars').on('click', function () {
            $('nav .nav-links').fadeIn(200);
            $('nav i.fa-bars').hide();
        });
        $('nav .fa-times, nav .submenu .fa-times, .nav-links a').on(
            'click',
            function () {
                $('nav .nav-links').fadeOut(200);
                $('nav .nav-links .menu-option .submenu').fadeOut(200);
                $('nav i.fa-bars').fadeIn(200);
            }
        );
        $('nav .menu-option').on('click', function () {
            $(this).children('.submenu').show();
        });
    }

    // Add link data attributes for tracking
    $('.SI-link a').each(function () {
        $(this).addClass('SI_Link_Top');
    });
    $('.nav-wrapper a').each(function () {
        $(this).addClass('Navigation_Link');
    });
    $('.billboard-slideshow-wrapper a').each(function () {
        $(this).addClass('Billboard_Link');
    });
    $('.home section a').each(function () {
        var sectionID = $(this).closest('section').attr('id');
        sectionID = sectionID.charAt(0).toUpperCase() + sectionID.slice(1);
        $(this).addClass(sectionID + '_Link');
    });
    $('footer a').each(function () {
        $(this).addClass('Footer_Link');
    });

    // Newsletter signup: allow click on images
    $('#form33 .subfield img').on('click', function () {
        $(this).next('input').click();
    });

    // Newsletter signup: prevent form submit if no items selected
    $('.subfield').on('click', function () {
        $('#formButton').show();

        $('.subfield input').each(function () {
            if ($(this).is(':checked')) {
                $('#formButton').hide();
            }
        });
    });

    // At the Smithsonian filters
    var firstCategory = $('.ats-category:first-of-type')
        .children('h3')
        .first()
        .text()
        .toLowerCase();
    var firstCategoryName = '.' + firstCategory;

    $('.ats-category:first-of-type').addClass('active');
    $('.ats-filter-item').hide();
    $(firstCategoryName).show();
    $('.ats-filter-item .text-wrapper div').hide();
    $(firstCategoryName + 'item').show();

    $('.ats-category').on('click', function () {
        var category = $(this).children('h3').text().toLowerCase();
        var categoryName = '.' + category;

        if (!$(this).hasClass('active')) {
            $('.ats-category').removeClass('active');
            $(this).addClass('active');
            $('.ats-filter-item').hide();
            $('.ats-filter-item .text-wrapper div').hide();
            $(categoryName).show();
            $(categoryName + 'item').show();
        }
    });
});
